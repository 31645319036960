import styled from 'styled-components';

export const Container = styled.div``;

export const Accordion = styled.div`
  position: relative;
  border: 2px solid #e5dbc6;
  border-radius: 5px;
  min-width: 330px;
`;
export const AccordionItem = styled.div``;
export const AccordionItemTitle = styled.label`
  background: #f2ead5;
  margin: 0;
  width: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #213f4e;
  font-size: 17px;
  font-weight: 600;
  padding: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 2px solid #e5dbc6;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &.subscriptions {
    border-top: 2px solid #e5dbc6;
    border-bottom: 0;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    &.active {
      border-bottom: 2px solid #e5dbc6;
      border-top: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  & > span {
    color: #d26546;
    margin-left: 5px;
    float: right;
    position: absolute;
    right: 12px;
    font-size: 15px;
  }
`;
export const AccordionItemRadio = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #d26546;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  background: #fff;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  outline: none;
  margin-right: 5px;
  cursor: pointer;

  &:before {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 18px;
    background: #d26546;
  }
`;

export const AccordionItemBody = styled.div`
  padding: 12px 20px;
  transition: all 0.2s ease;
  display: none;
  &.active {
    display: block;
  }
`;

export const OptionLabel = styled.p`
  line-height: 1.6;
  color: #213f4e;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-left: 15px;
  display: flex;
  & > span {
    width: 100%;
  }
  & > .right {
    text-align: right;
    margin-right: 25px;
    width: 50%;
  }
`;

export const OptionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const OptionListItem = styled.li`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #213f4e;

  & label {
    font-size: 16px;
    margin: 0;
    width: 100%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #213f4e;
    font-weight: 600;
    padding: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #d26546;
    &.active {
      background: #d26546;
      color: #fff;
      border-radius: 5px;
    }
  }

  & input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #d26546;
    width: 26px;
    height: 26px;
    border-radius: 26px;
    background: #fff;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    outline: none;
    margin-right: 5px;

    &:before {
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    &:checked:before {
      content: '';
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 18px;
      background: #d26546;
    }
  }

  & .title {
    display: inline;
    padding: 0px;
    width: 90%;
    & .text {
      max-width: 70%;
      display: inline-block;
    }
    & .price {
      right: 30px;
      float: right;
    }
  }
`;

export const AddToCartButton = styled.button`
  display: block;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  line-height: 1;
  padding: 13px 36px 13px 36px;
  width: 358px;
  max-width: 100%;
  border-radius: 4px;
  position: relative;
  text-transform: uppercase;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f4dea4),
    to(#ecc250)
  );
  background-image: linear-gradient(180deg, #f4dea4, #ecc250);
  border-color: #997e33;
  color: #333;
  width: 100%;
  margin: 10px 0;
  border: 2px solid #997e33;
  margin: 0 0 10px 0 !important;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f4dea4),
    to(#ecc250)
  );
  background-image: linear-gradient(180deg, #f4dea4, #ecc250);
  border-color: #997e33;
  color: #333;
  width: 100%;
  border: 2px solid #997e33;
  clear: both;
  cursor: pointer;
  & > span > i {
    @keyframes go {
      0% {
        right: 0;
      }

      50% {
        right: 10px;
      }

      100% {
        right: 0;
      }
    }
    -webkit-animation: go 0.8s infinite;
    animation: go 0.8s infinite;
    position: absolute;
    margin-right: 20px;
    top: 13px;

    &::after {
      content: ' ';
      display: block;
      background-image: url('/images/fa-chevron-right.svg');
      background-position: center center;
      background-repeat: no-repeat;
      width: 15px;
      height: 20px;
    }
  }
  &.processing {
    &:before {
      content: '';
      height: 22px;
      width: 100%;
      background-image: url('/images/three-dots.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
  }
`;

export const PaymentImage = styled.img`
  width: 100%;
`;

export const MadeIn = styled.div`
  text-align: center;
  margin: 5px 0 10px 0;
  color: #213f4e;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
`;

export const Flag = styled.img`
  margin: 0 8px 0 0;
  vertical-align: bottom;
`;

export const SubList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
`;

export const SubListItem = styled.li`
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #213f4e;
  font-size: 12px;
  padding: 0 0 10px 0;
  & > i {
    color: #739e5d;
    font-size: 20px;
    margin: 0 8px 0 0;
  }
`;

export const BumpOfferWrapper = styled.div`
  width: 100%;
  margin: 15px 0;
`;

export const BumpOffer = styled.div`
  padding: 10px 15px;
  -webkit-box-shadow: 0 0 7px 0 #b8b8b8;
  box-shadow: 0 0 7px 0 #b8b8b8;
`;

export const BumpOfferLabel = styled.label`
  position: relative;

  & input:checked + div:before {
    position: absolute;
    content: '';
    top: 23px;
    left: -1px;
    width: 8px;
    height: 18px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index: 1;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  & input:checked + div:after {
    position: absolute;
    content: '';
    top: 25px;
    left: 0;
    border: 2px solid #d26546;
    background-color: #d26546;
    z-index: 0;
    -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  }
`;

export const BumpOfferImage = styled.img`
  max-width: 65px !important;
  float: left;
  max-height: 60px;
`;

export const BumpOfferCheckbox = styled.input`
  display: none;
`;

export const BumpOfferContent = styled.div`
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  text-align: center;
  font-size: 13px;

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 25px;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  &:after {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #5a5a5a;
    top: 25px;
    left: 0;
    z-index: 0;
  }
`;

export const BumpOfferContentText = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 65px;
  font-size: 14px;
  color: #213f4e !important;
  margin: 0 !important;
  line-height: 1.6;
`;
