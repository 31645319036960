import React, { useState, useContext } from 'react';
import * as S from './ProductSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';
import { addToCart } from '../../utils/addToCart';

const ProductSelector = props => {
  const { hideBumpOfferDiscount, bumpOfferPrefixText } = props;

  const context = useContext(FunnelContext);
  const {
    currency,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    path,
    funnelUrl,
    setSubmittedToCart
  } = context;
  const [buttonClicked, setButtonClicked] = useState(false);
  const [bumpOfferChecked, setBumpOfferChecked] = useState(false);
  const [bumpOfferIndex, setBumpOfferIndex] = useState(0);

  const currencySymbol = currency?.symbol || '$';
  const currencyCode = currency?.code || 'USD';

  const extraData = {
    ...JSON.parse(context.extra),
    currency_code: currencyCode,
    currency_symbol: currencySymbol
  };

  const triggerAddToCart = () => {
    try {
      setButtonClicked(true);
      setSubmittedToCart(true);
      if (currentCategory === 'subscriptions') {
        const { discounts, products, bumpoffers } = context.subscription;
        const bumpOfferData = bumpOfferChecked
          ? bumpoffers[bumpOfferIndex]
          : {};
        products.map((product, i) => {
          if (currentProduct === `subscription_${i}`) {
            return addToCart(
              product,
              discounts,
              extraData,
              bumpOfferData,
              path,
              funnelUrl
            );
          }
          return null;
        });
      } else {
        const { discounts, products, bumpoffers } = context.onetime;
        const bumpOfferData = bumpOfferChecked
          ? bumpoffers[bumpOfferIndex]
          : {};
        products.map((product, i) => {
          if (currentProduct === `onetime_${i}`) {
            return addToCart(
              product,
              discounts,
              extraData,
              bumpOfferData,
              path,
              funnelUrl
            );
          }
          return null;
        });
      }
    } catch (err) {
      setButtonClicked(false);
      setSubmittedToCart(false);
    }
  };

  const AddToCartButton = ({ children }) => {
    return (
      <S.AddToCartButton
        type="button"
        onClick={triggerAddToCart}
        disabled={buttonClicked ? 'disabled' : ''}
        className={buttonClicked ? 'processing' : ''}
      >
        <span>
          {children}
          <i className="go" aria-hidden="true"></i>
        </span>
      </S.AddToCartButton>
    );
  };

  const BottomContent = ({ category }) => {
    const SubList = () => {
      if (category === 'onetime') return '';
      return (
        <S.SubList>
          <S.SubListItem>
            <i className="icon icon-check" aria-hidden="true"></i> Save 25%
            Every Order
          </S.SubListItem>
          <S.SubListItem>
            <i className="icon icon-check" aria-hidden="true"></i> Cancel
            Anytime
          </S.SubListItem>
          <S.SubListItem>
            <i className="icon icon-check" aria-hidden="true"></i> Swap Products
            & Dates
          </S.SubListItem>
          <S.SubListItem>
            <i className="icon icon-check" aria-hidden="true"></i> Free Shipping
          </S.SubListItem>
        </S.SubList>
      );
    };

    return (
      <>
        <S.PaymentImage src="/images/payments2.png"></S.PaymentImage>
        <S.MadeIn>
          <S.Flag src="/images/flag_us.png" />
          MADE IN & SHIPS FROM USA FACILITES
        </S.MadeIn>
        <SubList />
      </>
    );
  };

  const MaxDiscount = ({ products }) => {
    const discounts = products?.map(p => p.discount_percentage);
    return discounts ? Math.max(...discounts) : 0;
  };

  const OptionsSection = ({ products, prefix, price_suffix }) => {
    return (
      <>
        <S.OptionLabel>
          <span className="left">Choose an Option</span>
          <span className="right">Price</span>
        </S.OptionLabel>
        <S.OptionList>
          {products?.map((product, i) => (
            <S.OptionListItem key={i}>
              <label
                className={currentProduct === `${prefix}_${i}` ? `active` : ``}
              >
                <input
                  type="radio"
                  onChange={() => setCurrentProduct(`${prefix}_${i}`)}
                  name="product"
                  checked={currentProduct === `${prefix}_${i}` ? `checked` : ``}
                />
                <div className="title">
                  <span className="text">
                    {product.display_title} - You Save{' '}
                    {product.discount_percentage}%
                  </span>
                  <span className="price">
                    {currencySymbol}
                    {product.checkoutData.discounted_price}
                    {price_suffix}
                  </span>
                </div>
              </label>
            </S.OptionListItem>
          ))}
        </S.OptionList>
      </>
    );
  };

  const BumpOffer = ({ bumpoffers }) => {
    const handleBumpOffer = event => {
      setBumpOfferChecked(event.target.checked);
      setBumpOfferIndex(event.target.value);
    };

    const BumpOfferDiscount = ({ discount_percentage }) => {
      return hideBumpOfferDiscount ? (
        ``
      ) : (
        <span>({discount_percentage}% Off)</span>
      );
    };

    const prefix_text =
      bumpOfferPrefixText && bumpOfferPrefixText !== ``
        ? bumpOfferPrefixText
        : `Enhance Your Chews With Our`;
    return (
      <S.BumpOfferWrapper>
        {bumpoffers?.map(
          ({ checkoutData, discount_percentage, display_title }, index) => {
            return (
              <S.BumpOffer key={index}>
                <S.BumpOfferLabel>
                  <S.BumpOfferCheckbox
                    type="checkbox"
                    value={index}
                    checked={bumpOfferChecked}
                    onChange={handleBumpOffer}
                  />
                  <S.BumpOfferContent>
                    <S.BumpOfferImage
                      alt="bumpoffer"
                      src={checkoutData.image}
                    />
                    <S.BumpOfferContentText>
                      {prefix_text} {display_title} for {currencySymbol}
                      {checkoutData.discounted_price.toFixed(2)}{' '}
                      <BumpOfferDiscount
                        discount_percentage={discount_percentage}
                      />
                    </S.BumpOfferContentText>
                  </S.BumpOfferContent>
                </S.BumpOfferLabel>
              </S.BumpOffer>
            );
          }
        )}
      </S.BumpOfferWrapper>
    );
  };

  return (
    <FunnelContext.Consumer>
      {funnelData => (
        <S.Container>
          <S.Accordion>
            <S.AccordionItem>
              <S.AccordionItemTitle
                className={`onetime ${
                  currentCategory === 'onetime' ? `active` : ``
                }`}
              >
                <S.AccordionItemRadio
                  onChange={() => {
                    if (typeof setCurrentCategory === 'function') {
                      setCurrentCategory('onetime');
                    }
                    if (typeof setCurrentProduct === 'function') {
                      setCurrentProduct('onetime_0');
                    }
                  }}
                  type="radio"
                  name="category"
                  checked={currentCategory === 'onetime' ? `checked` : ``}
                />
                One-Time Purchases
              </S.AccordionItemTitle>
              <S.AccordionItemBody
                className={currentCategory === 'onetime' ? `active` : ``}
              >
                <OptionsSection
                  products={funnelData?.onetime?.products}
                  prefix="onetime"
                />
                <BumpOffer bumpoffers={funnelData?.onetime?.bumpoffers} />
                <AddToCartButton>Add to Cart</AddToCartButton>
                <BottomContent category="onetime" />
              </S.AccordionItemBody>
            </S.AccordionItem>

            <S.AccordionItem>
              <S.AccordionItemTitle
                className={`subscriptions ${
                  currentCategory === 'subscriptions' ? `active` : ``
                }`}
              >
                <S.AccordionItemRadio
                  onChange={() => {
                    if (typeof setCurrentCategory === 'function') {
                      setCurrentCategory('subscriptions');
                    }
                    if (typeof setCurrentProduct === 'function') {
                      setCurrentProduct('subscription_0');
                    }
                  }}
                  type="radio"
                  name="category"
                  checked={currentCategory === 'subscriptions' ? `checked` : ``}
                />
                Subscriptions &amp; Save
                <span>
                  Save up to{' '}
                  <MaxDiscount products={funnelData?.subscription?.products} />%
                  OFF
                </span>
              </S.AccordionItemTitle>
              <S.AccordionItemBody
                className={currentCategory === 'subscriptions' ? `active` : ``}
              >
                <OptionsSection
                  products={funnelData?.subscription?.products}
                  prefix="subscription"
                  price_suffix="/mo"
                />
                <BumpOffer bumpoffers={funnelData?.subscription?.bumpoffers} />
                <AddToCartButton>Subscribe Now</AddToCartButton>
                <BottomContent category="subscriptions" />
              </S.AccordionItemBody>
            </S.AccordionItem>
          </S.Accordion>
        </S.Container>
      )}
    </FunnelContext.Consumer>
  );
};

export default ProductSelector;
